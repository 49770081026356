/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react';
import { useLayout } from '../core';

const Footer: FC = () => {
  const { classes } = useLayout();
  return (
    <div className="footer py-4 d-flex flex-lg-column" id="kt_footer">
      {/* begin::Container */}
      <div className={`${classes.footerContainer} d-flex flex-column flex-md-row align-items-center justify-content-between`}>
        {/* begin::Copyright */}
        <div>
          <a href="https://apithon.com.tr/about-us.html" target={'_blank'} className="text-gray-800 text-hover-primary mx-3" rel="noreferrer">
            Hakkımızda
          </a>
          <a href="https://apithon.com.tr/contact.html" target={'_blank'} className="text-gray-800 text-hover-primary" rel="noreferrer">
            İletişim
          </a>
        </div>
        <div className="text-dark order-2 order-md-1">
          <span className="text-muted fw-bold me-2">{new Date().getFullYear()} &copy;</span>
          <a href="https://apithon.com.tr" target={'_blank'} className="text-gray-800 text-hover-primary" rel="noreferrer">
            Apithon Software
          </a>
        </div>

        {/* end::Copyright */}

        {/* begin::Nav */}

        {/* end::Nav */}
      </div>
      {/* end::Container */}
    </div>
  );
};

export { Footer };
